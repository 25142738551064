/**
 * @author Vahagn Lazyan.
 * @since 1.2.0
 */
import {Injectable} from '@angular/core';

import {FilterCriteria} from '@synisys/idm-de-core-frontend';

import {PortfolioCommunicationDto} from './portfolio-communication-dto';
import {Subject} from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class DynamicCommunicationService {

  public static createInstance() {
    return DynamicCommunicationService.INSTANCE;
  }

  private static readonly INSTANCE = new DynamicCommunicationService();
  private readonly _portfolioPropertiesSubject: Subject<PortfolioCommunicationDto>;
  private readonly _quickFilterSubject: Subject<Map<string, FilterCriteria>>;
  private readonly _quickFilterMap: Map<string, FilterCriteria> = new Map();

  constructor() {
    this._portfolioPropertiesSubject = new Subject<PortfolioCommunicationDto>();
    this._quickFilterSubject = new ReplaySubject<Map<string, FilterCriteria>>(1);
  }

  get portfolioPropertiesSubject(): Subject<PortfolioCommunicationDto> {
    return this._portfolioPropertiesSubject;
  }

  get portfolioPropertiesObservable(): Observable<PortfolioCommunicationDto> {
    return this._portfolioPropertiesSubject;
  }

  get quickFilterObservable(): Observable<Map<string, FilterCriteria>> {
    return this._quickFilterSubject;
  }

  public applyQuickFilter(key: string, filter: FilterCriteria): void {
    this._quickFilterMap.set(key, filter);
    this._quickFilterSubject.next(this._quickFilterMap);
  }

}
