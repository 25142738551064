import {map, mergeMap} from 'rxjs/operators';
import {LocaleInfo, LocaleService} from '@synisys/idm-locale-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class FormattingService {
    constructor(
        private readonly localeService: LocaleService,
        private readonly currentLanguageProvider: CurrentLanguageProvider
    ) {}

    public formatDate(utcTime: number): Observable<string> {
        return this.getLocaleInfo().pipe(
            mergeMap((localeInfo: LocaleInfo) => {
                // dates (without time) in de, sets time equal 00:00 by utc
                // must be installed by localeInfo.timezoneOffset, for correct work
                return this.localeService.formatDate(
                    utcTime + localeInfo.timeZoneOffset * 1000,
                    localeInfo.languageId
                );
            })
        );
    }

    public formatTime(utcTime: number): Observable<string> {
        return this.currentLanguageProvider.getCurrentLanguage().pipe(
            mergeMap((language: Language) => {
                return this.localeService.formatTime(utcTime, language.getId());
            })
        );
    }

    public formatDateTime(utcTime: number): Observable<string> {
        return this.currentLanguageProvider.getCurrentLanguage().pipe(
            mergeMap((language: Language) => {
                return this.localeService.formatDateTime(
                    utcTime,
                    language.getId()
                );
            })
        );
    }

    public formatNumber(number: number): Observable<string> {
        return this.currentLanguageProvider.getCurrentLanguage().pipe(
            mergeMap((language: Language) => {
                return this.localeService.formatNumber(
                    number,
                    language.getId()
                );
            })
        );
    }

    public getLocaleInfo(): Observable<LocaleInfo> {
        return this.currentLanguageProvider.getCurrentLanguage().pipe(
            mergeMap((language: Language) => {
                return this.localeService.getLocaleInfo(language.getId());
            }),
            map(value => {
                if (value['localeInfoDto']) {
                    return value['localeInfoDto'];
                }
                return value;
            })
        );
    }

    public parseNumber(num: string): Observable<number> {
        return this.getLocaleInfo().pipe(
            map((localeInfo: LocaleInfo) => {
                return parseFloat(
                    num
                        .split(localeInfo.numberFormat.groupSeparator)
                        .join('') // replaceAll
                        .replace(localeInfo.numberFormat.decimalSeparator, '.')
                );
            })
        );
    }
}
