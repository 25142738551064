import {map} from 'rxjs/operators';
import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {combineLatest} from 'rxjs/observable/combineLatest';

@Pipe({
  name: 'translateMessageToMultilingualString',
  pure: true,
})
export class TranslateMessageToMultilingualString implements PipeTransform {

  public constructor(private ref: ChangeDetectorRef,
                     private messageService: MessageService,
                     protected currentLanguageProvider: CurrentLanguageProvider) {
  }

  public transform(key: string): Observable<MultilingualString> {
    if (!key || !key.trim()) {
      return of(undefined);
    }
    const currentLanguage$ = this.currentLanguageProvider.getCurrentLanguage();
    const message$ = this.messageService.getMessage(key.trim());
    return combineLatest(currentLanguage$, message$).pipe(map((data: [Language, string]) =>
      MultilingualString.newBuilder().withValueForLanguage(data[0].getId(), data[1]).build()
    ));
  }

}
