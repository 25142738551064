import {MatDialog} from '@angular/material';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    MetaField,
    MetaFieldId,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {DeleteConfirmDialogComponent} from '../controls/delete-confirm-dialog';
import {HeaderItem} from './header-item.model';
import {entries} from 'lodash';
import {fromJS, Map} from 'immutable';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {OnDestroy} from '@angular/core';
import {FormActions} from './form-actions';
import {LocaleInfo} from '@synisys/skynet-store-locales-api';

export enum ColumnAlignmentType {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export abstract class BaseTable implements OnDestroy {
    private static readonly columnAlignmentClasses: Map<
        ColumnAlignmentType,
        string
    > = BaseTable.generateColumnAlignmentClasses();
    private static generateColumnAlignmentClasses(): Map<
        ColumnAlignmentType,
        string
    > {
        return fromJS({
            [ColumnAlignmentType.LEFT]: 'sis-popup-table__column--align-left',
            [ColumnAlignmentType.CENTER]:
                'sis-popup-table__column--align-center',
            [ColumnAlignmentType.RIGHT]: 'sis-popup-table__column--align-right',
        });
    }

    // input fields are abstract here for @input decoration to do in implementation and idea to make correct suggestions
    public abstract categoryName: string; //// subEntity compound Name
    public abstract rowItems: Entity[] | undefined;
    public abstract actions: FormActions;
    public abstract isDeletable: boolean;
    public abstract isAddable: boolean;
    public abstract colSystemNames: string[] | undefined;
    public abstract subFormKey: string;
    public abstract dialog: MatDialog;

    get rowToRemove(): Entity {
        return this._rowToRemove;
    }

    set rowToRemove(value: Entity) {
        this._rowToRemove = value;
    }

    get currentLanguage(): Language {
        return this._currentLanguage;
    }

    set currentLanguage(value: Language) {
        this._currentLanguage = value;
    }

    get localeInfo(): LocaleInfo {
        return this._localeInfo;
    }

    set localeInfo(value: LocaleInfo) {
        this._localeInfo = value;
    }

    get isReady(): boolean {
        return this._isReady;
    }

    set isReady(value: boolean) {
        this._isReady = value;
    }

    get columnMetaFields(): MetaField[] {
        return this._columnMetaFields;
    }

    set columnMetaFields(value: MetaField[]) {
        this._columnMetaFields = value;
    }

    get headers(): HeaderItem[] {
        return this._headers;
    }

    set headers(value: HeaderItem[]) {
        this._headers = value;
    }

    get alignments(): Map<string, string> {
        return this._alignments;
    }

    set alignments(value: Map<string, string>) {
        this._alignments = value;
    }

    protected _rowToRemove: Entity;
    protected destroySubject$: Subject<void> = new Subject<void>();
    private _headers: HeaderItem[];
    private _currentLanguage: Language;
    private _localeInfo: LocaleInfo;
    private _isReady = false;
    private _columnMetaFields: MetaField[];
    private _alignments: Map<string, string> = Map<string, string>();

    public abstract inlineAdd(): void;

    public abstract openAddPopup(): void;

    public removeAction(): void {
        const index = this.rowItems.indexOf(this._rowToRemove);
        if (index > -1) {
            this.rowItems.splice(index, 1);
        }
    }

    public ngOnDestroy() {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    public isThereTableHeader(): boolean {
        return !!this.headers && this.headers.length !== 0;
    }

    public isEmpty(): boolean {
        return !this.rowItems || this.rowItems.length === 0;
    }

    public isNumeric(metaField: MetaField): boolean {
        return (
            metaField.getType() === MetaFieldType.INTEGER ||
            metaField.getType() === MetaFieldType.BIG_DECIMAL ||
            metaField.getType() === MetaFieldType.DECIMAL
        );
    }

    public onRemove(row: Entity) {
        this._rowToRemove = row;
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
            disableClose: true,
            autoFocus: false,
            width: '400px',
            height: 'auto',
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                (result: boolean) => {
                    if (result) {
                        this.removeAction();
                    }
                },
                err => console.error(err)
            );
    }

    public abstract openEditPopup(rowItem: Entity): void;

    public isWithPopup(): boolean {
        return !!this.subFormKey;
    }

    public getUniqueIdForSubEntity(): number {
        const subEntityIds: number[] = this.rowItems.map(
            (subEntity: Entity) => {
                return subEntity.getId();
            }
        );
        const subEntityIdMinValue: number =
            this.rowItems.length > 0 ? Math.min(...subEntityIds) : 0;
        return subEntityIdMinValue > 0 ? -1 : subEntityIdMinValue - 1;
    }

    protected handleHeader(requiredMetaFieldIds: MetaFieldId[]) {
        if (this.columnMetaFields) {
            this._headers = this.columnMetaFields.reduce<HeaderItem[]>(
                (acc, metaField) =>
                    acc.concat(
                        new HeaderItem(
                            metaField.getDisplayNameMsgId(),
                            requiredMetaFieldIds.some(
                                requiredMetaField =>
                                    requiredMetaField.getSystemName() ===
                                    metaField.getSystemName()
                            ),
                            metaField.getDisplayNameMultilingual()
                        )
                    ),
                []
            );
        }
    }

    protected handleAlignments(col: MetaField, mappings: object) {
        if (mappings && Object.keys(mappings).length > 0) {
            this._alignments = this._alignments.withMutations(mutable => {
                entries(mappings).forEach(([key, value]) => {
                    if (col.getSystemName() === key) {
                        mutable.set(
                            key,
                            BaseTable.columnAlignmentClasses.get(
                                value.toString().toLocaleLowerCase(),
                                ''
                            )
                        );
                    }
                });
            });
        }
    }
}
