import {map, mergeMap} from 'rxjs/operators';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
    MetaFieldId,
} from '@synisys/idm-kb-service-client-js';
import {DeService} from '@synisys/idm-de-service-client-js';
import {
    DocumentService,
    FileType,
} from '@synisys/idm-document-service-client-js';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {Injectable} from '@angular/core';
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {Observable} from 'rxjs/Observable';
import {zip} from 'rxjs/observable/zip';
import {of} from 'rxjs/observable/of';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable()
export class ExtractionService {
    public static getStringFromField(
        entity: Entity,
        propertySystemName: string,
        currentLanguageId: number
    ): string {
        let result = entity.getProperty<MultilingualString | string>(
            propertySystemName
        ).value;
        if (result instanceof MultilingualString) {
            result = result.getValue(currentLanguageId);
        }
        return result;
    }

    private metaCategoryCache: Map<
        string,
        ReplaySubject<MetaCategory>
    > = new Map<string, ReplaySubject<MetaCategory>>();

    constructor(
        private kbService: KbService,
        private deService: DeService,
        private classifierService: ClassifierService,
        private documentService: DocumentService
    ) {}

    public extractNameFromClassifierCached(
        classifier: Entity,
        compoundCategorySystemName: string,
        currentLanguageId: number
    ): Observable<string> {
        if (classifier === null || classifier === undefined) {
            return of('');
        }
        return this.getMetaCategoryFromCache(compoundCategorySystemName).pipe(
            map((metaCategory: MetaCategory) => {
                return this.extractName(
                    metaCategory,
                    classifier,
                    currentLanguageId
                );
            })
        );
    }

    public extractNameFromEntityCached(
        entity: Entity,
        compoundCategorySystemName: string,
        currentLanguageId: number
    ): Observable<string> {
        return this.extractNameFromClassifier(
            entity,
            compoundCategorySystemName,
            currentLanguageId
        );
    }

    public extractNameFromClassifier(
        classifier: Entity,
        compoundCategorySystemName: string,
        currentLanguageId: number
    ): Observable<string> {
        if (classifier === null || classifier === undefined) {
            return of('');
        }

        return this.kbService
            .getMetaCategoryByMetaCategoryId(
                new MetaCategoryId(compoundCategorySystemName)
            )
            .pipe(
                map((metaCategory: MetaCategory) => {
                    return this.extractName(
                        metaCategory,
                        classifier,
                        currentLanguageId
                    );
                })
            );
    }

    public extractNameFromMultiSelect(
        classifiers: Entity[],
        compoundCategorySystemName: string,
        currentLanguageId: number
    ): Observable<string> {
        return zip(
            ...classifiers.map((classifier: Entity) =>
                this.extractNameFromClassifier(
                    classifier,
                    compoundCategorySystemName,
                    currentLanguageId
                )
            )
        ).pipe(
            map((classifiersNames: string[]) => {
                return classifiersNames.join('; ');
            })
        );
    }

    public extractNameFromMultiSelectCached(
        classifiers: Entity[],
        compoundCategorySystemName: string,
        currentLanguageId: number
    ): Observable<string> {
        return zip(
            ...classifiers.map((classifier: Entity) =>
                this.extractNameFromClassifierCached(
                    classifier,
                    compoundCategorySystemName,
                    currentLanguageId
                )
            )
        ).pipe(
            map((classifiersNames: string[]) => {
                return classifiersNames.join(', ');
            })
        );
    }

    public extractImageDocument(
        documentId: number,
        instanceId: number,
        categorySystemName: string,
        documentSystemName: string
    ): Observable<string> {
        return this.kbService.getClassifierMetaCategories().pipe(
            mergeMap((classifierMetaCategories: Set<MetaCategory>) => {
                for (const metaCategory of Array.from(
                    classifierMetaCategories
                )) {
                    if (metaCategory.getSystemName() === categorySystemName) {
                        return this.classifierService
                            .getDocumentAuthInfo(
                                categorySystemName,
                                instanceId,
                                documentSystemName
                            )
                            .pipe(
                                mergeMap((token: string) => {
                                    return this.documentService.getDocumentUrl(
                                        documentId,
                                        token,
                                        FileType.THUMBNAIL
                                    );
                                })
                            );
                    }
                }
                return this.deService
                    .getDocumentToken(
                        categorySystemName,
                        instanceId,
                        documentSystemName
                    )
                    .pipe(
                        mergeMap((token: string) => {
                            return this.documentService.getDocumentUrl(
                                documentId,
                                token,
                                FileType.THUMBNAIL
                            );
                        })
                    );
            })
        );
    }

    private extractName(
        metaCategory: MetaCategory,
        classifier: Entity,
        currentLanguageId: number
    ): string {
        if (metaCategory.getNameMetaFieldIds().length > 0) {
            return metaCategory
                .getNameMetaFieldIds()
                .map((metaFieldId: MetaFieldId) =>
                    ExtractionService.getStringFromField(
                        classifier,
                        metaFieldId.getSystemName(),
                        currentLanguageId
                    )
                )
                .join(' ');
        } else {
            return (
                classifier &&
                ExtractionService.getStringFromField(
                    classifier,
                    'name',
                    currentLanguageId
                )
            );
        }
    }

    private getMetaCategoryFromCache(
        compoundCategorySystemName: string
    ): Observable<MetaCategory> {
        if (this.metaCategoryCache.has(compoundCategorySystemName)) {
            return this.metaCategoryCache.get(compoundCategorySystemName);
        } else {
            const subject$: ReplaySubject<MetaCategory> = new ReplaySubject(1);
            this.metaCategoryCache.set(compoundCategorySystemName, subject$);
            this.kbService
                .getMetaCategoryByMetaCategoryId(
                    new MetaCategoryId(compoundCategorySystemName)
                )
                .subscribe(
                    (metaCategory: MetaCategory) => {
                        subject$.next(metaCategory);
                    },
                    err => console.error(err)
                );
            return subject$;
        }
    }
}
