import {Pipe, PipeTransform} from '@angular/core';
import {Entity} from '@synisys/idm-de-core-frontend';
import {ClassifierService, ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';

@Pipe({
  name: 'entityName',
  pure: true,
})
export class EntityNamePipe implements PipeTransform {

  constructor(public classifierService: ClassifierService) {

  }

  public transform(entity: Entity, categorySystemName: string): Observable<string> {
    if (entity instanceof ClassifierView) {
      return of(entity.getName());
    }

    return entity && this.classifierService.getEntityName(categorySystemName, entity);
  }

}
