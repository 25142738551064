import {FilterCriteria} from '@synisys/idm-de-core-frontend';

/**
 * @author Vahagn Lazyan.
 * @since 1.2.0
 */

export class PortfolioCommunicationDto {

  constructor(private readonly _data: FilterCriteria | undefined, private readonly _key: string) {
  }

  get data(): FilterCriteria | undefined {
    return this._data;
  }

  get key(): string {
    return this._key;
  }

}
