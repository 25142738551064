import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {buildMultilingualStringFromObject} from '@synisys/idm-frontend-shared';

export interface UserHelpItem {
    title: MultilingualString;
    url: string;
    documentId?: number;
}

export function userHelpItemFromObject(
    userHelpItemObject: object
): UserHelpItem {
    return {
        url: userHelpItemObject['url'],
        title: buildMultilingualStringFromObject(userHelpItemObject['title']),
        documentId: userHelpItemObject['documentId'],
    };
}
