import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {ExportDataLoaderService} from '../controls/header-export-button/export-data-loader.service';

export class DefaultExportDataLoaderService extends ExportDataLoaderService {
    public getExportData(
        categorySystemName: string,
        entityId: number
    ): Observable<object> {
        return of({});
    }
}
