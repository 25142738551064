import {A11yModule} from '@angular/cdk/a11y';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {BidiModule} from '@angular/cdk/bidi';
import {ObserversModule} from '@angular/cdk/observers';
import {OverlayModule} from '@angular/cdk/overlay';
import {PlatformModule} from '@angular/cdk/platform';
import {PortalModule} from '@angular/cdk/portal';
import {CdkTableModule} from '@angular/cdk/table';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgModule} from '@angular/core';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';

/**
 * NgModule that includes all Material modules that are required to serve the sis-ui-settings.
 */
@NgModule({
              exports: [
                  MatAutocompleteModule,
                  MatButtonModule,
                  MatButtonToggleModule,
                  MatCardModule,
                  MatCheckboxModule,
                  MatChipsModule,
                  MatTableModule,
                  MatDatepickerModule,
                  MatDialogModule,
                  MatDividerModule,
                  MatExpansionModule,
                  MatFormFieldModule,
                  MatGridListModule,
                  MatIconModule,
                  MatInputModule,
                  MatListModule,
                  MatMenuModule,
                  MatPaginatorModule,
                  MatProgressBarModule,
                  MatProgressSpinnerModule,
                  MatRadioModule,
                  MatRippleModule,
                  MatSelectModule,
                  MatSidenavModule,
                  MatSlideToggleModule,
                  MatSliderModule,
                  MatSnackBarModule,
                  MatSortModule,
                  MatStepperModule,
                  MatTabsModule,
                  MatToolbarModule,
                  MatTooltipModule,
                  MatNativeDateModule,
                  CdkTableModule,
                  A11yModule,
                  BidiModule,
                  CdkAccordionModule,
                  ObserversModule,
                  OverlayModule,
                  PlatformModule,
                  PortalModule,
                  FlexLayoutModule
              ]
          })

export class MaterialModule {}