import {map} from 'rxjs/operators';
import {Pipe, PipeTransform} from '@angular/core';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {of} from 'rxjs/observable/of';
import {Observable} from 'rxjs/Observable';

@Pipe({
        name: 'sisTranslateMultilingual',
        pure: true,
      })
export class SisTranslateMultilingualPipe implements PipeTransform {

  public constructor(private currentLanguageProvider: CurrentLanguageProvider) {
  }

  public transform(multilingual: MultilingualString | undefined): Observable<string> {
    // nothing to transform
    if (!multilingual) {
      return of('');
    }
    return this.currentLanguageProvider.getCurrentLanguage().pipe(
      map((language: Language) => multilingual.getValue(language.getId())),
    );
  }

}
