/**
 * @author tatevik.marikyan
 * @since 23/07/2018
 */
import {Pipe, PipeTransform} from '@angular/core';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {ValidationDisplayType} from '@synisys/idm-validation-calculation-service-client-js/src/model/validation-display-type';

@Pipe({
    name: 'sisValidationMessage',
    pure: true,
})
export class SisValidationMessagePipe implements PipeTransform {
    public transform(
        validations: Validation[] | undefined,
        fieldSystemName: string,
        currentLanguageId: number
    ): string | undefined {
        if (!validations) {
            return undefined;
        } else {
            const validation = validations.find(
                validationItem =>
                    !!this.getDisplayFields(validationItem).find(
                        displayForField => displayForField === fieldSystemName
                    )
            );

            return (
                validation &&
                validation.getMessage().getValue(currentLanguageId)
            );
        }
    }

    private getDisplayFields(validation: Validation): string[] {
        if (
            validation.getValidationDisplay() &&
            validation.getValidationDisplay().getFields() &&
            validation.getValidationDisplay().getDisplayType() ===
                ValidationDisplayType.FIELDS
        ) {
            const fields = validation.getValidationDisplay().getFields().get("fields");
            if (!fields) {
                return [];
            }
            return Array.isArray(fields) ? fields : (fields.fields || []);
        } else {
            return validation
                .getRelatedMetaFieldIds()
                .map(metaField => metaField.getSystemName());
        }
    }
}
